import { getNewsMediaPulseTracker } from '../../core/js/pulse/pulse-util.js';

export const initAppRedirect = async (
  appleStoreLink: string,
  googlePlayLink: string,
): Promise<void> => {
  const iOS = function () {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].indexOf(navigator.platform) != -1 ||
      // iPad on iOS 13 detection
      (navigator.userAgent.indexOf('Mac') != -1 && 'ontouchend' in document)
    );
  };

  const getMobileOperatingSystem = function () {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (iOS()) {
      return 'iOS';
    }

    return 'unknown';
  };

  const gogo = function () {
    const os = getMobileOperatingSystem();
    if (os == 'iOS') {
      window.location.href = appleStoreLink;
    } else if (os === 'Android') {
      window.location.href = googlePlayLink;
    }

    const appRedirectMain = document.getElementById('app-redirect-main');
    appRedirectMain.style.display = 'block';
  };

  let pageId = 'app-redirect';
  if (window.location.search.indexOf('qr') != -1) {
    pageId += '/qr';
  }

  const { trackViewListing } = await getNewsMediaPulseTracker();

  trackViewListing({
    object: {
      id: pageId,
      name: document.title,
    },
  }).then(() => {
    window.history.replaceState(
      window.history.state,
      '',
      window.location.pathname,
    );

    setTimeout(function () {
      gogo();
    }, 1000);
  });

  // fallback
  setTimeout(function () {
    gogo();
  }, 3000);
};
